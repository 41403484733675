<template>
	<div v-html="source" id="iframe-player" :style="heightDefault">

	</div>
</template>
<script>
import notify from "@/services/libs/notificacao";
export default {
	props: ['source', 'porcentagem', 'height'],
	data() {
		return {
			heightDefault: this.height ?? 500,
			options: {
				muted: false,
				autoplay: true,
			},
			playerReady: false,
			t: ''
		}
	},
	methods: {
		todo(type) {
			//console.log(type);
			if (type === 'play') {
				this.$refs.player.play();
			} else if (type === 'concluiu') {
				this.$root.$emit('liberaBtn');
			} else if (type === 'calcula') {
				this.$root.$emit('calculaBtn');
			} else if (type === 'next') {
				this.$root.$emit('liberaBtnNext');
			} else {
				this.$refs.player.pause();
				//console.log('pauseee');
			}
		},
		async calculaPorcentagem(type) {
			if (type === 'concluir') {
				this.todo('concluiu');
			} else if (type === 'calcula') {
				this.todo('calcula');
			} else {
				this.todo('next');
			}
		},
	},
	mounted() {
		this.$root.$on('clicouConcluir', data => {
			//console.log('xxxxxxxxx', data);
			if (data === 'iframe') {
				this.calculaPorcentagem('concluir');
			}
		});
		this.$root.$on('clicouNext', data => {
			if (data === 'iframe') {
				this.calculaPorcentagem('next');
			}
		});
		this.$root.$on('calcula', data => {
			if (data === 'iframe') {
				this.calculaPorcentagem('calcula');
			}
		});

	},
	beforeDestroy() {
		//console.log('beforeDestroy');
	},
}
</script>

<style>
.toZindex .playerVimeo {
	z-index: 99999999999999999;
}

#iframe-player iframe {
	width: -webkit-fill-available !important;
}

#iframe-player div {
    min-height: 500px;
}
</style>